import "aos/dist/aos.css";
import "@/assets/scss/config/modern/app.scss";
import "@vueform/slider/themes/default.css";
import "@/assets/scss/mermaid.min.css";
import "element-plus/dist/index.css";
import "dayjs/locale/ru";

import AOS from "aos";
import { API } from "@/api";
import App from "./App.vue";
import BootstrapVue3 from "bootstrap-vue-3";
import Echo from "laravel-echo";
import ElementPlus from "element-plus";
import Maska from "maska";
import Pusher from "pusher-js";
import VueApexCharts from "vue3-apexcharts";
import VueFeather from "vue-feather";
import { VueQueryPlugin } from "@tanstack/vue-query";
import { createApp } from "vue";
import { createPinia } from "pinia";
import i18n from "./i18n";
import router from "./router";
import ru from "element-plus/es/locale/lang/ru";
import store from "./state/store";
import vClickOutside from "click-outside-vue3";

const pinia = createPinia();

AOS.init({
  easing: "ease-out-back",
  duration: 1000,
});

window.Pusher = Pusher;

window.Echo = new Echo({
  broadcaster: "reverb",
  key: process.env.VUE_APP_REVERB_APP_KEY,
  wsHost: process.env.VUE_APP_REVERB_HOST,
  wsPort: process.env.VUE_APP_REVERB_PORT,
  wssPort: process.env.VUE_APP_REVERB_PORT,
  forceTLS: (process.env.VUE_APP_REVERB_SCHEME ?? "https") === "https",
  enabledTransports: ["ws", "wss"],
  // authorizer: () => new Object({ authorize: () => API.post("/broadcasting/auth") }),
  authorizer: (channel) =>
    new Object({
      authorize: (socketId, callback) =>
        API.post("/broadcasting/auth", { socket_id: socketId, channel_name: channel.name })
          .then((response) => callback(false, response.data))
          .catch((error) => callback(true, error)),
    }),
});

createApp(App)
  .use(store)
  .use(router)
  .use(VueApexCharts)
  .use(BootstrapVue3)
  .component(VueFeather.type, VueFeather)
  .use(Maska)
  .use(i18n)
  .use(vClickOutside)
  .use(pinia)
  .use(VueQueryPlugin)
  .use(ElementPlus, {
    locale: ru,
    zIndex: 2045,
  })
  .mount("#app");
